
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import useAgencyRepositories from "@/repositories/useAgencyRepositories";
import FilterTable from '@/components/common/FilterTable.vue'
import { formatterDollars } from '@/utils/formatter'
import { formatDateTime } from '@/utils/dateTime'
import moment from 'moment'
export default defineComponent({
  props: {
    baseCost: {
      type: Object,
    },
  },
  components: {
    FilterTable,
  },
  setup(props) {
    const state = reactive({
      search: ref<string>(''),
      showModalAddAgency: ref(false),
      tableData: ref<any[]>([]),
      agencies: ref<any[]>([]),
      selectedAgencies: ref<any[]>([]),
      addedAgencies: ref<any[]>([]),
      deletedAgencies: ref<any[]>([]),
      selectedCatalogs: ref<any[]>([]),
      columns: ref([
        {
          title: 'Name',
          dataIndex: 'name',
        },
        {
          title: 'Code',
          dataIndex: 'code',
        },
        {
          title: 'Email',
          dataIndex: 'email',
        },
        {
          title: 'Created',
          dataIndex: 'createdAt',
        },
        {
          title: 'Action',
          dataIndex: 'action',
        },
      ]),
    })
    const params = reactive({
      page: 1,
      limit: 10,
      name: '',
    })
    const fomartDate = (strTime: string) => {
      return moment(strTime).format('MM/DD/YYYY')
    }

    const { getAgencies } = useAgencyRepositories();

    onMounted(async () => {
      let resAgencies = await getAgencies({
        page: 1,
        limit: 10,
      });
      state.agencies = resAgencies as any[];

      if (props && props.baseCost && props.baseCost.id) {
        let agencies = await getAgencies({ basecost: `${props?.baseCost?.id}`, })
        state.selectedAgencies = agencies as any[];
      }

    })

    let availableAgencies = computed(() =>
      state.agencies.filter(
        s => !state.selectedAgencies.find(sl => sl.id === s.id)
      )
    )

    const onAddAgency = (c: any) => {
      let selectedAgency = { ...c }
      state.selectedAgencies = [...state.selectedAgencies, selectedAgency]
      state.addedAgencies = [...state.addedAgencies, selectedAgency]
    }

    const onDeleteAgency = (agency: any) => {
      let findIndex = state.selectedAgencies.findIndex(s => s.id === agency.id)
      if (findIndex !== -1) {
        state.selectedAgencies.splice(findIndex, 1)
      }
      state.deletedAgencies = [...state.deletedAgencies, agency]
    }

    const onSearchSelectedAgencies = async () => {
      let selectedAgencies = [...state.selectedAgencies]
      selectedAgencies = selectedAgencies.filter((s: any) =>
        s.name.toLowerCase().includes(state.search.toLowerCase())
      )
      console.log('selectedAgencies', selectedAgencies)
    }

    return {
      state,
      params,
      availableAgencies,
      formatterDollars,
      formatDateTime,
      onAddAgency,
      onDeleteAgency,
      onSearchSelectedAgencies,
      fomartDate
    }
  },
})
