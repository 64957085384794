
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import { ArrowDown } from '@element-plus/icons'
import { imageUrl } from '@/utils/image'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import useBaseCostReponsitories from '@/repositories/useBaseCostReponsitories'
import { Plus, Edit, Delete } from '@element-plus/icons'
import { ElMessageBox, ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { useFetchData, useNotification } from '@/composables'
import SlotError from '@/components/common/SlotError.vue'
import { useResource } from '@/services'

export default defineComponent({
  components: {
    ArrowDown,
    Delete,
    Edit,
    SlotError,
  },
  props: {
    baseCost: {
      type: Object,
    },
    group: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      tableData: ref<any[]>([]),
      multipleSelection: [],
      columns: ref([
        {
          title: 'Created',
          dataIndex: 'created',
        },
        {
          title: 'Name',
          dataIndex: 'name',
        },
        {
          title: 'Code',
          dataIndex: 'catalogCode',
        },
        {
          title: 'Balance',
          dataIndex: 'balance',
        },
      ]),
      showModal: false,
      action: '$',
      showModalAddSet: false,
      type: ref('add'),
      selectedCatalogs: ref<any[]>([]),
      baseCostCatalogs: ref<any[]>([]),
      addedCatalogs: ref<any[]>([]),
      deletedCatalogs: ref<any[]>([]),
      catalogs: ref<any[]>([]),
      listBaseCost: ref<any[]>([]),
      radio1: 'Add',
      baseCost: ref<string>(''),
      options: ref<any[]>([]),
      selectionCatalogs: ref<any[]>([]),
      searchCatalog: ref<string>(''),
      filter: {
        search: ref<string>(''),
      },
    })
    const route = useRoute()
    const router = useRouter()
    const { getCatalogs, getCatalogsDefault } = userCatalogRepositories()
    const { error, success } = useNotification()

    const { updateBaseCost } = useBaseCostReponsitories()

    onMounted(() => {
      getData()
    })

    const filterTableData = computed(() =>
      state.baseCostCatalogs.filter(
        data =>
          !state.filter.search ||
          data.name.toLowerCase().includes(state.filter.search.toLowerCase())
      )
    )

    let availableCatalogs = computed(() => {
      if (!state.baseCost) {
        return state.catalogs.filter(
          c => !state.selectedCatalogs.find(s => s._id === c._id)
        )
      }
      return state.catalogs.filter(
        c =>
          !state.selectedCatalogs.find(s => s._id === c._id) &&
          c.basecost === state.baseCost
      )
    })

    const getData = async () => {
      loadBaseCostCatalogs()
      if (props && props.baseCost && props.baseCost.id) {
        state.type = 'edit'
      }
      const { documents, fetchData } = useFetchData('basecosts/admin', {
        page: 1,
        limit: 20,
      })
      await fetchData()
      let listBaseCost = documents.value
      state.listBaseCost = listBaseCost as any[]
      state.options = []

      let optionBase = {
        label: 'Base Cost Default',
        options: state.listBaseCost
          .filter(c => !c.group)
          .map(c => {
            return {
              label: c.name,
              value: c.id,
            }
          }),
      }
      state.options = [...state.options, optionBase]
    }

    const loadBaseCostCatalogs = async () => {
      if (props && props.baseCost && props.baseCost.id) {
        let res = await getCatalogs({
          page: 1,
          limit: 1000,
          dropship: false,
          basecost: props.baseCost.id,
        })
        state.baseCostCatalogs = res as any[]
      }
    }

    const handleSelectionChange = (val: any) => {
      state.multipleSelection = val
    }

    const onSelectionCatalogs = (val: any) => {
      state.selectionCatalogs = val
    }

    const showModalCatalog = () => {
      state.showModal = true
    }

    const onChangeBaseCost = async () => {
      let baseCost = state.listBaseCost.find(b => b.id === state.baseCost)
      let currentCatalogs = state.baseCostCatalogs.map(c => c._id)
      let params = {
        basecost: state.baseCost,
        isDefaultAdminBaseCost: baseCost?.isDefaultAdminBaseCost,
        exclude_ids: currentCatalogs?.join(',') || '',
        page: 1,
        limit: 1000,
      }
      if (baseCost.isDefaultAdminBaseCost) {
        let resDefault = await getCatalogsDefault({
          page: 1,
          limit: 1000,
          dropship: false,
        })
        state.catalogs = resDefault as any[]
      } else {
        let res = await getCatalogs(params)
        state.catalogs = res as any[]
      }
    }

    const onSearchCatalogs = async () => {
      let params = {
        basecost: state.baseCost,
        page: 1,
        limit: 1000,
        dropship: false,
        search: state.searchCatalog,
      }
      let res = await getCatalogs(params)
      state.catalogs = res as any[]
    }

    const onAddCatalog = (el: any) => {
      let selectedCatalog = { ...el }
      if (state.type === 'edit') {
        let exists = state.baseCostCatalogs.find(
          s => s.productCode === selectedCatalog.productCode
        )
        if (exists) {
          ElMessageBox.confirm(
            'Overwrite any existing catalogs that have the same product code.',
            'Warning',
            {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning',
            }
          )
            .then(() => {
              onSubmitCatalogs([selectedCatalog])
            })
            .catch(() => {
              console.log(123)
            })
        } else {
          onSubmitCatalogs([selectedCatalog])
        }
      } else {
        state.selectedCatalogs = [...state.selectedCatalogs, selectedCatalog]
        state.addedCatalogs = [...state.addedCatalogs, selectedCatalog]
        state.baseCostCatalogs = [...state.baseCostCatalogs, selectedCatalog]
      }
    }

    const onDeleteCatalog = async (catalog: any) => {
      if (state.type === 'edit') {
        let params = {
          ...props.baseCost,
          catalogs: {
            add: [],
            delete: [catalog._id],
          },
          group: props.group,
        }
        let res = await updateBaseCost(params)
        if (res.status === 201 || res.status === 200) {
          loadBaseCostCatalogs()
        } else {
          error(res.data.message)
        }
      } else {
        let findIndex = state.baseCostCatalogs.findIndex(
          s => s._id === catalog._id
        )
        if (findIndex !== -1) {
          state.selectedCatalogs.splice(findIndex, 1)
          state.baseCostCatalogs.splice(findIndex, 1)
        }
        let findIndexAdded = state.addedCatalogs.findIndex(
          s => s._id === catalog._id
        )
        if (findIndexAdded !== -1) {
          state.addedCatalogs.splice(findIndex, 1)
        }
      }
    }

    const onShowAddSet = () => {
      state.showModalAddSet = true
    }

    const onSubmitCatalogs = async (catalogs: any[]) => {
      try {
        console.log('onSubmitCatalogs')

        let addedCatalogs = [...catalogs]
        let addedCatalogsIds = addedCatalogs.map((s: any) => s._id)
        let params = {
          ...props.baseCost,
          catalogs: {
            add: addedCatalogsIds,
            delete: [],
          },
          group: props.group,
        }
        let res = await updateBaseCost(params)
        if (res.status === 200 || res.status === 201) {
          loadBaseCostCatalogs()
        } else {
          error(res.data.message)
        }
        return res
      } catch (error: any) {
        console.log('Error', error.response.data)
      }
    }

    const onAddSelectionCatalogs = async () => {
      if (state.type === 'edit') {
        let exists = state.selectionCatalogs.filter(
          s =>
            state.baseCostCatalogs.findIndex(
              c => c.productCode === s.productCode
            ) !== -1
        )
        if (exists && exists.length > 0) {
          ElMessageBox.confirm(
            'Overwrite any existing catalogs that have the same product code.',
            'Warning',
            {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning',
            }
          )
            .then(async () => {
              await onSubmitCatalogs(state.selectionCatalogs)
              state.showModal = false
            })
            .catch(() => {
              console.log(123)
            })
        } else {
          await onSubmitCatalogs(state.selectionCatalogs)
          state.showModal = false
        }
      } else {
        state.selectedCatalogs = [...state.selectionCatalogs]
        state.addedCatalogs = [...state.selectionCatalogs]
        state.baseCostCatalogs = [
          ...state.baseCostCatalogs,
          ...state.selectionCatalogs,
        ]
      }
    }

    return {
      state,
      availableCatalogs,
      router,
      handleSelectionChange,
      showModalCatalog,
      onDeleteCatalog,
      onAddCatalog,
      onShowAddSet,
      imageUrl,
      onSelectionCatalogs,
      onAddSelectionCatalogs,
      onChangeBaseCost,
      onSearchCatalogs,
      filterTableData,
    }
  },
})
